.customButton {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  background-color: #662d91;
  border-radius: 44px;
  padding: 8px 32px;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 600;
  color: white;
  transition: color 0.25s, background-color 0.25s;
  border: 1px solid #662d91;
  cursor: pointer;
}

.customButton.fullWidth {
  width: 100%;
}

.customButton.centered {
  margin: 0 auto;
}

.customButton:hover {
  color: #662d91;
  background-color: transparent;
}
