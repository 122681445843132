.button-box {
    width: 100%;
    padding: 8px 0px;
    display: flex;
    position: relative;
    border-radius: 40px;
    background: #fff;
    box-shadow: 0px 3px 3px rgba(0, 0, 0,0.35);
}

.toggle-button {
    width: 50%;
    background: transparent;
    border: none;
    position: relative;
    text-align: center;
    color: white;
}

#shadow {
    left: 0;
    top: 0;
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: #663192;
    border-radius: 40px;
    transition: .5s;
}

button {
    font-weight: bold;
    font-size: 12px;
}

#left-button {
    color: white;
}

#right-button {
    color: #663192;
}