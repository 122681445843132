.card {
  width: 100%;
  max-width: 688px;
  border-radius: 20px;
  border: 1px solid #dddfe1;
  padding: 24px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card.fullWidth {
  max-width: 100%;
}

.card.removeBoxShadow {
  box-shadow: none;
}
