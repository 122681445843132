.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 36px;
  width:80%;
  padding: 16px 0 28px;
  margin: 40px auto 0 auto;
  background-color:"secondary";
}

.navbar > ul {
  list-style: none;
  display: flex;
  gap: 20px;
  align-items: center;
}

.navbar > ul > li > a,
#companyMenu a{
  display: block;
  border: 1px ;
  color: #333;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  text-transform: unset;
  font-weight: 400;
}

.navbar > ul > li > button:hover {
  background-color: unset;
}

.navbar > ul > li > a.active,
#companyMenu a.active{
  color: #662d91;
  text-decoration: underline;
}

.navbar__logo {
  width: 64px;
  height: 64px;
  cursor: pointer;
}

.arrowDown{
  width: 14px;
  padding-left: 3px;
}

.signIn-div{
  display: flex;
  gap: 18px;
  align-items: center;
  margin-left: auto;
}

.loginButton{
  text-decoration:none;
  color: black;
}

#companyButton,
#productsButton{
 color: #333;
 text-transform: none;
}

@media screen and (max-width: 768px) {
  .navbar > ul {
    display: none;
  }
}