.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  background-color:white ;
  border-radius: 24px;
  width: fit-content;
  padding: 8px 60px;
  text-transform: none;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 400;
  color: #662d91 ;
  transition: color 0.25s, background-color 0.25s;
  border: 1px solid #662d91;
  cursor: pointer;
}
.button:hover {
  color: white;
  background-color: #662d91;
}
