.hamburgerButton {
  display: inline-block;
  cursor: pointer;
  margin-left: auto;
  user-select: none;
}

.hamburgerButton_bar1,
.hamburgerButton_bar2,
.hamburgerButton_bar3 {
  width: 35px;
  height: 5px;
  border-radius: 8px;
  background-color: #333333;
  margin: 6px 0;
  transition: 0.4s;
}

@media screen and (min-width: 769px) {
  .hamburgerButton {
    display: none;
  }
}
