.menu {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  touch-action: none;
}

.menu::before {
  content: "";
  position: absolute;
  background-color: #DeDeDe;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation: menuAnimation 0.3s ease-in-out;
}

.menu__link {
  position: relative;
  color: black;
  z-index: 4;
  font-size: 24px;
  text-decoration: none;
  transition: color 0.3s;
  animation: link_closeButton_Animation 1.5s;
  user-select: none;
}

.menu__link.active {
  color: #662d91;
}

@keyframes menuAnimation {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

.closeButton {
  z-index: 5;
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 24px;
}

.closeButton_bar1,
.closeButton_bar2 {
  width: 55px;
  height: 8px;
  border-radius: 8px;
  background-color: black;
  margin: 6px 0;
  transition: 0.1.5s;
  animation: link_closeButton_Animation 0.25s;
}

/* Rotate first closeButton_bar */
.closeButton_bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 1px);
  transform: rotate(-45deg) translate(-9px, 1px);
}

/* Fade out the second closeButton_bar */
.closeButton_bar2 {
  -webkit-transform: rotate(45deg) translate(-9px, 1px);
  transform: rotate(45deg) translate(-9px, 1px);
}

@keyframes link_closeButton_Animation {
  0% {
    color: transparent;
    background-color: transparent;
    pointer-events: none;
  }
  50% {
    pointer-events: none;
  }
  100% {
  }
}
