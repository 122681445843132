.nav-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    width: 100%;
    padding: 8px;
    border-radius: 12px;
    color: black;
}

.nav-link.active {
    background-color: #662d91;
    color: white;
}

.nav-link:hover {
    box-shadow: 0 1px 3px 0 grey,
        0 1px 3px 0 grey;
}