@font-face {
  font-family: "Poppins";
  src: url(./assets/fonts/Poppins-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url(./assets/fonts/Poppins-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url(./assets/fonts/Poppins-Bold.ttf);
  font-weight: 700;
}

html {
  scroll-behavior: smooth;
}

html,
body,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

.csv-export {
  display: inline-flex;
  margin-bottom: 8px;
  text-decoration: unset;
  color: white;
  align-items: center;
  gap: 8px;
  background-color: #662d91;
  padding: 8px;
  border-radius: 22px;
  border: 1px solid #662d91;
  transition: background-color 0.3s, color 0.3s;
}

.csv-export:hover {
  color: #662d91;
  background-color: white;
}

@media screen and (min-width: 769px) {
  .display-mobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .display-desktop {
    display: none;
  }
}
