.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: medium;
  background-color:white ;
  border-radius: 30px;
  width: fit-content;
  padding: 0 22px;
  text-transform: none;
  border: 1px solid #662d91;
  cursor: pointer;
  font-weight: 500
}


.arrowDiv { 
  display: flex;
  border-radius: 11px;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 769px) {
  .responsive-button-font-size {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .responsive-button-font-size {
    font-size: 15px;
  }
}